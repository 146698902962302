/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react'

const getTotalSecondsRemaining = unlockTime => {
  const t = Date.parse(unlockTime) - Date.parse(new Date())
  const totalSeconds = t / 1000
  return totalSeconds
}

const getSecondsRemaining = totalSeconds => {
  const secondsRemaining = Math.floor(totalSeconds % 60)
  return ('0' + secondsRemaining).slice(-2)
}

const getMinutesRemaining = totalSeconds => {
  const minutesRemaining = Math.floor((totalSeconds / 60) % 60)
  return ('0' + minutesRemaining).slice(-2)
}

const getHoursRemaining = totalSeconds => {
  const hoursRemaining = Math.floor((totalSeconds / 3600) % 24)
  return ('0' + hoursRemaining).slice(-2)
}

const getDaysRemaining = totalSeconds => {
  const daysRemaining = Math.floor(totalSeconds / 86400)
  return daysRemaining
}

const Countdown = ({ unlockTime }) => {
  const [totalSeconds, setTotalSeconds] = useState(
    getTotalSecondsRemaining(unlockTime),
  )
  const [seconds, setSeconds] = useState('00')
  const [minutes, setMinutes] = useState('00')
  const [hours, setHours] = useState('00')
  const [days, setDays] = useState('0')

  useEffect(() => {
    if (totalSeconds > 0) {
      setSeconds(getSecondsRemaining(totalSeconds))
      setMinutes(getMinutesRemaining(totalSeconds))
      setHours(getHoursRemaining(totalSeconds))
      setDays(getDaysRemaining(totalSeconds))
    }
    let timer = setTimeout(() => setTotalSeconds(totalSeconds - 1), 1000)

    return () => {
      clearTimeout(timer)
    }
  }, [totalSeconds])

  return (
    <h1 className="px-4 text-center font-bold text-4xl md:text-8xl uppercase">
      <span className="bg-clip-text text-transparent bg-gradient-to-r from-pink-400 to-blue-500">
        {days}d {hours}h {minutes}m {seconds}s
      </span>
    </h1>
  )
}

export default Countdown
