/* eslint-disable react/prop-types */
import React, { useEffect } from 'react'
import { Link, graphql } from 'gatsby'

import SEO from '../components/seo'
import Countdown from '../components/countdown/index'

const Easy = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title
  const unlockTime = new Date(2021, 6, 12, 1, 0, 0, 0)

  return (
    <div className="w-full bg-gray-900">
      <SEO title={siteTitle} keywords={[`baby`, `boy`, `girl`, `longhurst`]} />
      <div className="h-screen flex flex-col justify-center">
        <p className="px-4 text-center text-white text-2xl md:text-3xl">
          Easy mode unlocks in...
        </p>
        <Countdown unlockTime={unlockTime} />
        <div className="mx-auto flex justify-center mt-10 w-1/2 ">
          <Link
            className="inline-flex items-center px-6 py-3 border text-base font-medium rounded-full shadow-sm text-white bg-gradient-to-r from-blue-400 to-pink-400 hover:via-pink-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            to="/hard"
          >
            Try hard mode 😉
          </Link>
        </div>
      </div>
    </div>
  )
}

export default Easy

export const easyPageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        author
      }
    }
  }
`
